require("base-layout");
require("sub-navigation");
require("sprites");
// Expose handlebars-helpers for client
var Handlebars = require("handlebars/runtime").default;
var registerHelpers = require("ui-handlebars-helpers");
registerHelpers(Handlebars, true);
var $ = require("lc-jquery");
var FormManager = require("ui-form-manager");
var validator = require("ui-validator");
var v = validator.validators;
var Modal = require("ui-modal");
var CurrencyInput = require("currency-input");
var faqAccordion = require("faq-accordion");

const dynamicRegistry = require("dynamic-module-registry");

var educatorDetailsModal = new Modal($("#educatorDetailsModal"));
var educatorPrpDetailsModal = new Modal($("#educatorPrpDetailsModal"));
var educatorPrpSamplePaymentModal = new Modal($("#educatorPrpSamplePaymentModal"));
new CurrencyInput(document);
var calculatorModal = new Modal($("#calculator-modal"));
new faqAccordion(".faq-accordion");
require("plans");

const renderCalcComponent = require("calculator/calculator-component").default;

function EducatorPage() {
  this.$searchForm = $("#search-form");
  this.$mobileSearchForm = $("#mobile-search-form");
  this.formManager = this.registerForm(this.$searchForm);
  this.registerEvents();
}

function setAccessibilityFocus(field) {
  $("#" + field).focus();
}

EducatorPage.prototype.registerEvents = function() {
  $(".educator-details").on("click keypress", function(event) {
    if (event.type === "click" || event.which === 13 || event.which === 32){ // mouse click or enter or space
      educatorDetailsModal.show();
    }
  });
  $(".educator-prp-details").on("click keypress", function(event) {
    if (event.type === "click" || event.which === 13 || event.which === 32){ // mouse click or enter or space
      educatorPrpDetailsModal.show();
    }
  });
  $(".educator-prp-sample-payment").on("click keypress", function(event) {
    if (event.type === "click" || event.which === 13 || event.which === 32){ // mouse click or enter or space
      educatorPrpSamplePaymentModal.show();
    }
  });

  $(".calculator-modal-button").on("click", function() {
    renderComponent();
    calculatorModal.show();
    setAccessibilityFocus("amount");
  });
  $("body").on("click keypress", "[name=close-result]", $.proxy(function(event) {
    if (event.type === "click" || event.which === 13 || event.which === 32){ // mouse click or enter or space
      this.closeSearchResults();
    }
  }, this));
  this.$searchForm.on("submit", $.proxy(this.verifyInputFields, this));
  this.$mobileSearchForm.on("submit", $.proxy(this.redirectMobileToSearch, this));
  this.formManager.on("success", $.proxy(this.successHandler, this));
};

EducatorPage.prototype.registerForm = function(form) {
  var formManager = new FormManager(form, {
    endPoint: {
      url: "/educator/search",
      dataType: "json",
      method: "GET"
    },
    schema: {
      name: {
        length: v.inputLength({min: 0, max: 100})
      },
      address: {
        length: v.inputLength({min: 0, max: 100})
      }
    }
  });
  return formManager;
};


EducatorPage.prototype.verifyInputFields = function() {
  if (!$("input[name=name]").val() && ! $("input[name=address]").val()) {
    window.location.href = "/family/search?";
  }
};

EducatorPage.prototype.successHandler = function(data) {
  if (data.status === 500) {
    window.location.href = "/family/search?name=&address=";
  }

  if (data.total === 1) {
    this.displaySingleResult(data.clients[0]);
  }
  if (data.total > 1 || data.total === 0) {
    this.redirectToSearch();
  }
};


EducatorPage.prototype.redirectMobileToSearch = function(e) {
  e.preventDefault();
  var queryString = "name=" + $("input[name=mobileName]").val() + "&address=" + $("input[name=mobileAddress]").val();
  window.location = "family/search?" + queryString;
};

EducatorPage.prototype.redirectToSearch = function() {
  var queryString = "name=" + $("input[name=name]").val() + "&address=" + $("input[name=address]").val();
  window.location.href = "family/search?" + queryString;
};

$(document).ready(function() {
  setAccessibilityFocus("email");
  new EducatorPage();
  $(".close-btn").on("click keypress", (event) => {
    if (event.type === "click" || event.which === 13 || event.which === 32){ // mouse click or enter or space
      calculatorModal.hide();
    }
  });
});

const renderComponent = () => {
  const { getStartedURL } = dynamicRegistry.get("calcModel");
  const options = {
      navigation: {
          text: "Educators Enroll Now",
          onClick: (e) => {
            window.open(getStartedURL);
            calculatorModal.hide();
          }
      },
      onPrint: (e, { amount, brand }) => {
          window.open(`/payment-calculator/print?amount=${amount}&page=educator`);
          calculatorModal.hide();
      }
  };
  renderCalcComponent(options);
};

module.exports = {};

require('/var/lib/jenkins/workspace/pf-yts-ui_Release_0/src/pages/educator/educator.less');